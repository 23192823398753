import React from 'react';

function SubmitButton({onClick}) {
  return (
    <div className="row">
      <button type="submit" className="submit-form" style={{cursor: 'pointer'}} onClick={onClick}>
        Сохранить
      </button>
    </div>
  );
}

export default SubmitButton;
