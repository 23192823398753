import React from 'react';

function ZoomInIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2492 1.11035C10.2492 0.696138 9.91339 0.360352 9.49918 0.360352C9.08496 0.360352 8.74918 0.696138 8.74918 1.11035V8.25015L1.60938 8.25015C1.19516 8.25015 0.859375 8.58594 0.859375 9.00015C0.859375 9.41437 1.19516 9.75015 1.60938 9.75015H8.74918V16.89C8.74918 17.3042 9.08496 17.64 9.49918 17.64C9.91339 17.64 10.2492 17.3042 10.2492 16.89V9.75015H17.389C17.8032 9.75015 18.139 9.41437 18.139 9.00015C18.139 8.58594 17.8032 8.25015 17.389 8.25015L10.2492 8.25015V1.11035Z"
        fill="#1D1D1F"
      />
    </svg>
  );
}

export default ZoomInIcon;
