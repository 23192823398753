import React from 'react';

function PlusIcon() {
  return (
    <svg
      className="svg-plus"
      width="40px"
      height="40px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 8V16" stroke="#000000" strokeWidth="0.1" />
      <path d="M8 12H16" stroke="#000000" strokeWidth="0.1" />
    </svg>
  );
}

export default PlusIcon;
