import React, { useEffect, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import PlusIcon from './icons/PlusIcon';
import CloseIcon from './icons/CloseIcon';
import ChangePhotoIcon from './icons/ChangePhotoIcon';
import DeleteEmployeeIcon from './icons/DeleteEmployeeIcon';
import Croppi from './Croppi/Croppi';

function EmployeeManager({ onEmployeeData }) {
  const [employees, setEmployees] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedEmployeePhoto, setSelectedEmployeePhoto] = useState(null);
  const toggleComponent = (id, photo) => {
    setIsOpen(!isOpen);
    setSelectedEmployeeId(id);
    setSelectedEmployeePhoto(photo);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen]);

  useEffect(() => {
    onEmployeeData(employees);
  }, [employees, onEmployeeData]);

  const addEmployee = () => {
    const newEmployee = {
      id: _uniqueId('prefix-'),
      name: '',
      photo: null,
      fullPhoto: null,
    };
    setEmployees([...employees, newEmployee]);
  };

  const deleteEmployee = (id) => {
    const updatedEmployees = employees.filter((employee) => employee.id !== id);
    setEmployees(updatedEmployees);
  };

  const handleNameChange = (event, id) => {
    let value = event.target.value;
    value = value.replace(/[^A-Za-z]/gi, ''); // prevent from typing non-english name
    const updatedEmployees = employees.map((employee) =>
      employee.id === id
        ? {
          ...employee,
          name: value,
        }
        : employee,
    );
    setEmployees(updatedEmployees);
  };

  const handlePhotoChange = (photoData, fullPhotoData) => {
    const updatedEmployees = employees.map((employee) =>
      employee.id === selectedEmployeeId
        ? {
          ...employee,
          photo: photoData,
          fullPhoto: fullPhotoData,
        }
        : employee,
    );
    setEmployees(updatedEmployees);
    setIsOpen(false);
  };

  return (
    <div className="employee-section" id="employees">
      {employees.map((employee) => (
        <div key={employee.id} className="employee">
          <input
            className="employee-name-input"
            type="text"
            placeholder="ИМЯ"
            value={employee.name}
            onChange={(e) => handleNameChange(e, employee.id)}
          />
          <button
            className="delete-employee-button float-button"
            onClick={() => deleteEmployee(employee.id)}
          >
            <DeleteEmployeeIcon />
          </button>

          {employee.photo ? (
            <>
              <img src={employee.photo} alt={`${employee.name}`} />
              <button
                className="change-photo-button float-button"
                onClick={() => toggleComponent(employee.id, employee.fullPhoto)}
              >
                <ChangePhotoIcon />
              </button>
            </>
          ) : (
            <button
              className="employee-add-photo-button float-button"
              onClick={() => toggleComponent(employee.id)}
            >
              Загрузить фото
            </button>
          )}
        </div>
      ))}
      {isOpen && selectedEmployeeId !== null && (
        <div className="popup-container">
          <div className="popup">
            <button
              className="close-popup"
              onClick={() => toggleComponent(null)}
            >
              <CloseIcon />
            </button>
            <h2 className={'popup-name'}>Отредактируйте фото</h2>
            <Croppi
              onPhotoChange={handlePhotoChange}
              photo={selectedEmployeePhoto}
            />
          </div>
        </div>
      )}
      <button
        className="add-employee-button float-button"
        onClick={addEmployee}
      >
        <PlusIcon />
      </button>
    </div>
  );
}

export default EmployeeManager;
