import React from 'react';

function ZoomOutIcon() {
  return (
    <svg
      width="19"
      height="2"
      viewBox="0 0 19 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1749 1C18.1749 1.41421 17.8391 1.75 17.4249 1.75L1.57617 1.75C1.16196 1.75 0.826172 1.41421 0.826172 1C0.826172 0.585786 1.16196 0.25 1.57617 0.25L17.4249 0.250001C17.8391 0.250001 18.1749 0.585788 18.1749 1Z"
        fill="#1D1D1F"
      />
    </svg>
  );
}

export default ZoomOutIcon;
