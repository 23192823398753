import React from 'react';

export const BadgeFrame = ({ className }) => {
  return (
    <svg
      width="300"
      height="450"
      viewBox="0 0 200 300"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="200" height="300" stroke="black" strokeWidth="2" />
      <rect
        x="75"
        y="30"
        width="50"
        height="10"
        rx="5"
        fill="white"
        stroke="black"
        fillOpacity={'70%'}
        strokeWidth="1"
      />
    </svg>
  );
};

export default BadgeFrame;
