import React from 'react';
import cn from 'classnames';
import { isNumber } from 'advanced-cropper';
import { Slider } from './SliderCroppi';
import './Navigation.css';
import ZoomOutIcon from '../icons/ZoomOutIcon';
import ZoomInIcon from '../icons/ZoomInIcon';

export const Navigation = ({ className, onZoom, zoom }) => {
  const onZoomIn = () => {
    if (onZoom && isNumber(zoom)) {
      onZoom(Math.min(1, zoom + 0.25), true);
    }
  };

  const onZoomOut = () => {
    if (onZoom && isNumber(zoom)) {
      onZoom(Math.max(0, zoom - 0.25), true);
    }
  };

  return (
    <div className={cn('absolute-zoom-cropper-navigation', className)}>
      <button className="button" onClick={onZoomOut}>
        <ZoomOutIcon />
      </button>
      <Slider
        value={zoom}
        onChange={onZoom}
        className="absolute-zoom-cropper-slider"
      />
      <button className="button" onClick={onZoomIn}>
        <ZoomInIcon />
      </button>
    </div>
  );
};
