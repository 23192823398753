import React, { useEffect, useRef, useState } from 'react';
import { DefaultSize, FixedCropper, ImageRestriction, StencilSize } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { WrapperCroppi } from './WrapperCroppi';

export const Croppi = ({ onPhotoChange, photo }) => {
  const inputRef = useRef(null);
  const cropperRef = useRef(null);
  const defaultImage = require('../../images/Palm.png');
  let [image, setImage] = useState(photo || defaultImage);
  const [errorMessage, setErrorMessage] = useState(null);


  const stencilSize: StencilSize = () => {
    return {
      width: 300, height: 450,
    };
  };

  const defaultSize: DefaultSize = ({ imageSize }) => {
    return {
      width: imageSize.width, height: imageSize.height,
    };
  };

  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onLoadImage = (event) => {
    const file = event.target.files && event.target.files[0];

    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        const height = image.height;
        const width = image.width;
        if (height < 1063 || width < 709) {
          setErrorMessage('Минимальный размер 709×1063 рх или 60×90 мм.');
        } else {
          setImage(image.src);
          setErrorMessage(null);
        }
      };
    };

    event.target.value = '';
  };

  const onCrop = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
      if (canvas) {
        onPhotoChange(canvas.toDataURL('image/jpeg'), image);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [image]);


  return (<div className="popup-wrapper">
    <div className="preview-img">
      <FixedCropper
        minWidth={709} // мин размер рамочки
        minHeight={1063}
        ref={cropperRef}
        className={'cropper'}
        stencilProps={{
          resizable: false,
          previewClassName: 'preview',
          overlayClassName: 'overlay',
          movable: false,
          scalable: false,
          lines: false,
          handlers: false,
          aspectRatio: 2 / 3,
        }}
        defaultSize={defaultSize}
        boundaryClassName={'boundary-cropper'}
        wrapperComponent={WrapperCroppi}
        src={image}
        imageRestriction={ImageRestriction.stencil}
        stencilSize={stencilSize}
        transitions={false}
      />
      {errorMessage && <p className={'error-message-photo'}>{errorMessage}</p>}
      <input
        id={'uploadImageButton'}
        onClick={onUpload}
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onLoadImage}
      />
      <div className="controls">
        <button className="choose-img float-button"
                onClick={() => document.getElementById('uploadImageButton').click()}>
          Выбрать фото
        </button>
        {image === defaultImage ? (<p>Выбери фото, чтобы сохранить.</p>) : (
          <button className="save-img float-button" onClick={onCrop}>
            Cохранить
          </button>)}
      </div>
    </div>
  </div>);
};

export default Croppi;
