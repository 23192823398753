import React from 'react';

export const HowToPrepare = () => {
  return (
    <>
      <h2 className={'popup-name'}>Как подготовить фото</h2>
      <div className={'popup-wrapper'} style={{ padding: '0 5%' }}>
        <div className="row">
          <img
            style={{ marginTop: '0px' }}
            className="instruction-img"
            src={require('../images/howto1.png')}
            alt={'Настройка фото 1'}
          />
          <label>
            Использовать любой удобный графический редактор для обработки
            фотографий.
          </label>
          <p style={{ marginBottom: '-10px' }}>
            Размер в пикселях: ширина 709px, высота 1063px <br />
            Размер в мм: 60х90мм <br />
            Отступ сверху под отверстие: 150px или 14мм
          </p>
        </div>
        <hr />
        <div className="row">
          <img className="instruction-img" src={require('../images/howto2.png')} alt={'Настройка фото 2'} />
          <label>Отредактировать с помощью смартфона</label>
          <p style={{ marginBottom: '-10px' }}>Пропорция фотографии: 2:3</p>
        </div>
        <hr />
        <div className="row">
          <img className="instruction-img" src={require('../images/howto3.png')} alt={'Настройка фото 3'} />
          <label>Применить чёрно-белый фильтр</label>
        </div>
      </div>
    </>
  );
};

export default HowToPrepare;
