import React, { useEffect, useState } from 'react';
import CloseIcon from './icons/CloseIcon';
import HowToPrepare from './HowToPrepare';
import PhotoRequirements from './PhotoRequirements';

function Info() {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState();

  const toggleComponent = (content) => {
    setIsOpen(!isOpen);
    setContent(content);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen]);

  return (
    <>
      <div className="info">
        <h2 className={'field-name'}>Фото</h2>
        <p>
          Фото должно быть черно-белым. Минимальный размер 709×1063 рх или 60×90
          мм. Пропорция 2:3. Отступ сверху под отверстие 150 рх или 14 мм.
        </p>
        <a onClick={() => toggleComponent(<HowToPrepare />)}>
          Как подготовить фото
        </a>
        <a onClick={() => toggleComponent(<PhotoRequirements />)}>
          Требования к фото
        </a>
        <h2 className={'field-name'}>Имя</h2>
        {isOpen && (
          <div className="popup-container">
            <div className="popup">
              <button className="close-popup" onClick={() => toggleComponent()}>
                <CloseIcon />
              </button>
              <>{content}</>
            </div>
          </div>
        )}
        <p>
          Заполняется латиницей. Можно использовать сокращения, например,
          Тимофей — Tim, Александра — Sasha. Нельзя использовать
          уменьшительно-ласкательные и шутливые формы.
        </p>
      </div>
    </>
  );
}

export default Info;
