import React from 'react';

function DeleteEmployeeIcon() {
  return (
    <svg
      className="delete-icon"
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 4H13M12.5 4H10M12.5 4C11.9477 4 11.5 4.44772 11.5 5V16C11.5 16.5523 11.0523 17 10.5 17H2.5C1.94772 17 1.5 16.5523 1.5 16V5C1.5 4.44772 1.05228 4 0.5 4M10 4C9.44772 4 9 3.55228 9 3V2C9 1.44772 8.55229 1 8 1H5C4.44771 1 4 1.44772 4 2V3C4 3.55228 3.55228 4 3 4M10 4H3M8 8V14M0.5 4H0M0.5 4H3M5 8V14"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteEmployeeIcon;
