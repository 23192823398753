import React from 'react';

function CloseIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L13 13M13 13L25 25M13 13L25 1M13 13L1 25" stroke="black" />
    </svg>
  );
}

export default CloseIcon;
