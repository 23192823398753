import React from 'react';
import cn from 'classnames';
import { CropperFade } from 'react-advanced-cropper';
import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';
import { Navigation } from './NavigationCroppi';
import BadgeFrame from '../BadgeFrame';

export const WrapperCroppi = ({ cropper, children, loaded, className }) => {
  const state = cropper.getState();

  const settings = cropper.getSettings();

  const absoluteZoom = getAbsoluteZoom(state, settings);

  const onZoom = (value: number, transitions?: boolean) => {
    cropper.zoomImage(getZoomFactor(state, settings, value), {
      transitions: !!transitions,
    });
  };


  return (<>
      <CropperFade className={cn('cropper-wrapper', className)} visible={state && loaded}>
        {children}
        <BadgeFrame className={'badge-frame'} />
      </CropperFade>
      <Navigation className="cropper-wrapper-navigation" zoom={absoluteZoom} onZoom={onZoom} />
    </>
  );
};

export default WrapperCroppi;