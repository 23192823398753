import React from 'react';

function ChangePhotoIcon() {
  return (
    <svg
      className="change-photo-icon"
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.21875 5.26088V15C1.21875 15.5523 1.66647 16 2.21875 16H19.784C20.3362 16 20.784 15.5523 20.784 15V5.26088C20.784 4.7086 20.3362 4.26088 19.784 4.26088H17.4371C17.0858 4.26088 16.7603 4.07658 16.5796 3.77537L15.2057 1.48551C15.025 1.1843 14.6995 1 14.3482 1H7.32842C6.97715 1 6.65164 1.1843 6.47092 1.48551L5.09701 3.77537C4.91628 4.07658 4.59078 4.26088 4.23951 4.26088H2.21875C1.66647 4.26088 1.21875 4.70859 1.21875 5.26088Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <circle cx="11" cy="9" r="3.91304" stroke="black" strokeWidth="1.5" />
    </svg>
  );
}

export default ChangePhotoIcon;
