import React from 'react';

export const PhotoRequirements = () => {
  return (<>
    <h2 className={'popup-name'}>Требования к фото</h2>
    <div className={'popup-wrapper'} style={{ padding: '0 5%' }}>
      <div className="row">
        <img className="instruction-img" style={{ marginTop: '0px' }} src={require('../images/photoreq1.png')}
             alt={'Требование 1'} />
        <label>Кадр</label>
        <p style={{ marginBottom: '-10px' }}>
          Анфас или 3/4. Взгляд в камеру. Выражение лица расслабленное,
          улыбающееся, доброжелательное, без масок, фильтров и негативных
          эмоций. Лицо открыто, его не должны закрывать волосы, головной убор,
          руки, пальмовые ветки и т.д.
        </p>
      </div>
      <hr />
      <div className="row">
        <img className="instruction-img" src={require('../images/photoreq2.png')} alt={'Требование 2'} />
        <label>Внешний вид</label>
        <p style={{ marginBottom: '-10px' }}>
          Внешний вид опрятный. Чистые, аккуратные волосы, одежда не открывает
          плечи. Головные уборы допустимы, если не закрывают лица. Проследите,
          чтоб цвет фона и цвет одежды были контрастными и не сливались друг с
          другом.
        </p>
      </div>
      <hr />
      <div className="row">
        <img className="instruction-img" src={require('../images/photoreq3.png')} alt={'Требование 3'} />
        <label>Стиль</label>
        <p style={{ marginBottom: '-10px' }}>
          Фотография не подразумевает строгий/официальный стиль, ровно так же,
          как и не должна быть взята из чата с друзьями. Расслабьтесь,
          подумайте о прекрасном и будьте собой, чтобы лет через десять, когда
          найдёте этот бейдж, он согрел бы вас тёплыми воспоминаниями о
          времени, проведённом со своей командой.
        </p>
      </div>
      <hr />
      <div className="row">
        <img
          src={require('../images/photoreq4.png')}
          alt={'Требование 4'}
          style={{ width: '100%', marginBottom: '20px', marginTop: '20px' }}
        />
        <label>Примеры недопустимого заполнения информации</label>
        <p style={{ marginBottom: '-10px' }}>
          Соблюдение всех стандартов Surf Coffee®, в том числе и стандарта
          внешнего вида является обязательным.
        </p>
      </div>
    </div>
  </>);
};

export default PhotoRequirements;
